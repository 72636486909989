<template>
    <div class="searchResultCard">
        <p class="h4 colorFiord searchResultCard__title">
            {{ searchResultTitle }}
        </p>
        <p class="text colorGray searchResultCard__subtitle">
            {{ searchResultSubTitle }}
        </p>
    </div>
</template>

<script>
export default {
    name: "searchResultCard",

    props: {
        searchResultTitle: String,
        searchResultSubTitle: String,
    },
};
</script>

<style lang="scss">
@import "./SearchResultCard.scss";
</style>
