<template>
    <Layout class="knowledgeBaseSearchLayout">
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/knowledge-base`"
        />

        <div class="pageContent">
            <div class="knowledgeBaseSearch">
                <div class="knowledgeBaseSearch__content">
                    <div class="knowledgeBaseSearch__contentSearch">
                        <p class="h3 knowledgeBaseSearch__contentSearch_title">
                            По Вашему запросу найдено 4 результата
                        </p>
                        <p
                            class="text2 colorFiord knowledgeBaseSearch__contentSearch_subtitle"
                        >
                            Ваш запрос ”Обучениe старт”
                        </p>
                        <div class="knowledgeBaseSearch__contentSearch_input">
                            <SearchInput
                                placeholder="Начните писать вопрос и выберите подходящий из списка ниже"
                                @getSearchValue="getSearchValue"
                            />
                        </div>
                        <div
                            v-if="!inputSearchValue"
                            class="knowledgeBaseSearch__contentSearchResult"
                        >
                            <div
                                class="knowledgeBaseSearch__contentSearchResult_cardLayout"
                                v-for="item in resultItems"
                                :key="item.searchResultTitle"
                            >
                                <SearchResultCard
                                    :searchResultTitle="item.searchResultTitle"
                                    :searchResultSubTitle="item.searchResultSubTitle"
                                />
                            </div>
                        </div>
                        <p
                            v-if="inputSearchValue"
                            class="h3 colorHeather knowledgeBaseSearch__contentSearchFalseResult"
                        >
                            По Вашему запросу не найдено никаких результатов
                        </p>
                    </div>
                    <div class="knowledgeBaseSearch__contentSupport">
                        <p class="h4 knowledgeBaseSearch__contentSupport_title">
                            Не нашли ответ?
                        </p>
                        <p
                            class="text2 colorGray knowledgeBaseSearch__contentSupport_text"
                        >
                            Напишите нам - мы с радостью поможем Вам и ответим на все
                            вопросы!
                        </p>
                        <Button
                            class="knowledgeBaseSearch__contentSupport_btn"
                            extraClass="button--white"
                            title="НАПИСАТЬ"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SearchInput from "@/views/components/SearchInput/SearchInput";
import Button from "@/views/components/Button/Button";
import SearchResultCard from "@/views/components/SearchResultCard/SearchResultCard";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

export default {
    name: "knowledgeBaseSearch",

    components: {
        Layout,
        Button,
        SearchInput,
        SearchResultCard,
        PageHeader,
    },

    data() {
        return {
            title: "Поиск",
            items: [
                {
                    text: "База знаний",
                    to: "/knowledge-base",
                },
                {
                    text: "Поиск",
                    active: true,
                },
            ],
            resultItems: [
                {
                    searchResultTitle: "Text1",
                    searchResultSubTitle: "SubText1",
                },
                {
                    searchResultTitle: "Text2",
                    searchResultSubTitle: "SubText2",
                },
                {
                    searchResultTitle: "Text3",
                    searchResultSubTitle: "SubText3",
                },
                {
                    searchResultTitle: "Text4",
                    searchResultSubTitle: "SubText4",
                },
            ],

            inputSearchValue: "",
        };
    },

    methods: {
        getSearchValue(value) {
            this.inputSearchValue = value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "knowledgeBaseSearch.scss";
</style>
